<template>
  <Modal :value="value" has-footer width="540px" @input="closeModal">
    <!-- title -->

    <template #title>{{ title }}</template>

    <!-- ... -->

    <template #default>
      <ValidationObserver ref="form">
        <template v-if="!editFromFileSheet">
          <div
            v-for="field in _repositoryField"
            :key="field.id"
            class="field-wrapper"
          >
            <template>
              <!-- <template v-if="field.dataType === 'TABLE'">
              <TableField v-model="fieldsModel[field.name]" :field="field" />
            </template> -->
              <template>
                <RepositoryField
                  v-model="fieldsModel[field.name]"
                  :field="field"
                  :repository-id="file.repositoryId"
                  :read-only="readOnly"
                  class="col"
                />
              </template>
            </template>
          </div>
        </template>
        <template v-if="editFromFileSheet">
          <div
            v-for="field in fieldsModalData"
            :key="field.id"
            class="field-wrapper"
          >
            <template>
              <!-- <template v-if="field.dataType === 'TABLE'">
              <TableField v-model="fieldsModel[field.name]" :field="field" />
            </template> -->
              <template>
                <RepositoryField
                  v-model="field.newValue"
                  :field="getField(field.fieldId)"
                  :repository-id="file.repositoryId"
                  :read-only="readOnly"
                  class="col"
                />
              </template>
            </template>
          </div>
        </template>
      </ValidationObserver>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Modal>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Modal from "@/components/common/popup/Modal.vue";
import RepositoryField from "@/views/upload-and-index/views/indexer/components/repository-fields/components/RepositoryField.vue";
// import TableField from "@/views/upload-and-index/views/indexer/components/repository-fields/components/table-field/TableField.vue";
import { isEmpty, lowerCase } from "lodash-es";

export default {
  name: "MetaDataEdit",

  components: {
    Modal,
    ValidationObserver,
    RepositoryField,
    // TableField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    file: {
      type: Object,
      required: true,
    },

    repositoryField: {
      type: Array,
      required: true,
    },

    fileData: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      default: "Metadata Edit",
    },

    columns: {
      type: Array,
      default: () => [],
    },

    modalValue: {
      type: Object,
      default: () => {},
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    uploadFilename: {
      type: String,
      default: "",
    },

    editFromFileSheet: {
      type: Boolean,
      default: false,
    },

    metaDataFields: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      fieldsModel: {},
      fieldsModalData: [],
    };
  },

  computed: {
    _repositoryField() {
      return this.repositoryField.map((field) => ({
        ...field,
        options: field.options ? JSON.parse(field.options) : [],
      }));
    },
  },

  watch: {
    fileData: {
      immediate: true,
      deep: true,
      async handler() {
        if (!this.editFromFileSheet) {
          this.processFieldsModel();
        } else {
          this.fieldsModalData = [];
          if (this.fileData.length) {
            this.fileData.forEach((item) => {
              this.fieldsModalData.push({
                fieldId: item.id,
                // dataType: item.type,
                oldValue: item.value,
                newValue: item.value,
              });
            });
          }
        }
      },
    },

    value() {
      if (this.value) {
        this.processFieldsModel();
      }
    },
  },

  methods: {
    processFieldsModel() {
      this.fieldsModel = {};
      if (!this.fileData.length) {
        this._repositoryField.forEach((field) => {
          let isColumn = this.columns.find(
            (item) => lowerCase(item.label) === lowerCase(field.name)
          );
          // console.log(isColumn);
          if (isColumn) {
            // let fileName = this.repositoryField.reduce((max, curren) =>
            //   max.level > curren.level ? max : curren
            // );

            // if (fileName.name !== field.name) {
            if (this.modalValue.formData) {
              this.$set(
                this.fieldsModel,
                field.name,
                this.modalValue.formData.fields[isColumn.id]
              );
            } else if (this.modalValue[isColumn.name]) {
              this.$set(
                this.fieldsModel,
                field.name,
                this.modalValue[isColumn.name]
              );
            } else if (this.modalValue[isColumn.id]) {
              this.$set(
                this.fieldsModel,
                field.name,
                this.modalValue[isColumn.id]
              );
            }
            // } else {
            //   this.$set(this.fieldsModel, field.name, "");
            // }
          } else if (!isEmpty(this.metaDataFields)) {
            //
            this.$set(
              this.fieldsModel,
              field.name,
              this.metaDataFields[field.name]
            );
          } else {
            this.$set(this.fieldsModel, field.name, "");
          }
        });
      } else {
        this.fileData.forEach((field) => {
          // let fileName = this.repositoryField.reduce((max, curren) =>
          //   max.level > curren.level ? max : curren
          // );

          // if (fileName.name !== field.name) {
          this.$set(this.fieldsModel, field.name, field.value);
          // } else {
          //   this.$set(this.fieldsModel, field.name, "");
          // }
        });
      }
      if (this.uploadFilename) {
        let filename = this.uploadFilename.substring(
          0,
          this.uploadFilename.lastIndexOf(".")
        );
        let filenameField = this._repositoryField.find(
          (field) => field.name.toLowerCase() == "filename"
        );
        if (filenameField) {
          this.$set(this.fieldsModel, filenameField.name, filename);
        }
      }
    },

    closeModal() {
      this.$emit("close", false);
    },

    async save() {
      //if (this.editFromFileSheet) {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      //}

      this.$emit(
        "save",
        this.editFromFileSheet ? this.fieldsModalData : this.fieldsModel
      );
      this.$emit("close", false);
    },

    getField(id) {
      let field = this._repositoryField.find((item) => id === item.id);
      if (field) return field;
    },
  },
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}
</style>
