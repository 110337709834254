<template>
  <div id="view-wrapper">
    <div v-if="showMinimumFileSheet" class="row justify-end">
      <div class="col-md-6 col-lg-6">
        <div id="file-sheet" class="shadow-3">
          <!-- header -->

          <div class="header">
            <div class="title-1">
              <div id="file">
                <!-- <BaseActionButton
                  v-tooltip.top="'files'"
                  is-flat
                  icon="eva-menu-2"
                  class="q-ml-sm"
                  @click="$emit('show-files')"
                /> -->
                <div class="avatar">
                  <FileIcon :mime-type="minimumFileSheet.type" />
                </div>

                <div class="q-ml-md">
                  <div class="filename">
                    {{ minimumFileSheet.name }}
                  </div>
                </div>
              </div>
            </div>

            <q-space />

            <BaseActionButton
              is-flat
              icon="eva-expand-outline"
              class="q-mr-sm"
              @click="handleOpenFullFileSheet"
            />

            <BaseCloseButton @click="hideFileSheet" />
          </div>

          <!-- ... -->

          <!-- content -->

          <BaseScrollbar id="file-content" height="calc(100vh - 125px)">
            <div class="file-list">
              <iframe id="file-frame" :src="fileLink" frameborder="0"></iframe>
            </div>
          </BaseScrollbar>

          <!-- ... -->
        </div>
      </div>
    </div>

    <!-- header -->
    <Header :title="title" :breadcrumbs="breadcrumbs">
      <template #actions>
        <slot name="header-actions"></slot>
      </template>
    </Header>
    <!-- ... -->

    <!-- action bar -->
    <ActionBar
      v-if="!hideActionBar"
      :tab="tab"
      :tabs="tabs"
      :hide-actionbar="!showMinimumFileSheet"
    >
      <template #header-title>
        <slot name="header-title"></slot>
      </template>
    </ActionBar>

    <!-- ... -->

    <!-- content -->

    <LoadingBar v-if="loadingBarPage" />

    <slot name="default"></slot>

    <!-- ... -->

    <!-- <FileSheet
      v-model="isFileSheetVisible"
      :file="selectedFile"
      :repository-field="repositoryField"
    /> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "./components/Header.vue";
import ActionBar from "./components/ActionBar.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
} from "@/helpers/file-format.js";
import LoadingBar from "@/components/common/LoadingBar.vue";

export default {
  name: "ViewWrapper",

  components: { Header, ActionBar, FileIcon, LoadingBar },

  props: {
    title: {
      type: String,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      required: true,
    },

    hideActionBar: {
      type: Boolean,
      default: false,
    },

    tab: {
      type: String,
      default: "",
    },

    tabs: {
      type: Array,
      default: () => [],
    },

    minimumFileSheet: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      showMinimumFileSheet: false,
    };
  },

  computed: {
    ...mapState(["loadingBarPage"]),

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.minimumFileSheet.name) {
        let ext = this.minimumFileSheet.name.split(".").pop();
        let domainURL = "";
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          } else if (
            origin === "https://trial.ezofis.com" &&
            !otherFilePDFSupport(ext)
          ) {
            domainURL = "https://trial.ezofis.com/docsviewer/index.html";
          } else if (origin === "https://app.ezofis.com") {
            domainURL =
              "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
          } else {
            domainURL = `${origin}/PDFViewer/web/viewer.html`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          domainURL = "http://52.172.32.88/DocsViewer/index.html";
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.$route.query.repositoryId}/${this.minimumFileSheet.id}/2`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.$route.query.repositoryId}&itemId=${this.minimumFileSheet.id}&type=2&filename=${this.minimumFileSheet.name}`;
      } else {
        return "";
      }
    },
  },

  watch: {
    minimumFileSheet: {
      deep: true,
      handler() {
        if (this.minimumFileSheet) {
          this.showMinimumFileSheet = true;
          this.$emit("hideFileSheet", true);
        }
      },
    },

    $route: {
      deep: true,
      handler() {
        if (!this.$route.query.repositoryId) {
          this.hideFileSheet();
        }
      },
    },
  },

  methods: {
    handleOpenFullFileSheet() {
      this.hideFileSheet();
      this.$emit("handleOpenFullFileSheet", this.minimumFileSheet);
    },

    hideFileSheet() {
      this.$emit("hideFileSheet", false);
      this.showMinimumFileSheet = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#file-sheet {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  padding: 10px;
  // height: 100%;
  cursor: pointer;
  position: fixed;
  width: 48%;
  margin-top: -15px;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--divider-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#file {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#file-content {
  // min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  #file-frame {
    width: 100%;
    height: calc(100vh - 125px);
  }
}
</style>
