<template>
  <q-drawer
    v-model="drawer"
    :mini="miniDrawer"
    side="left"
    :width="240"
    :mini-width="52"
  >
    <div id="drawer">
      <!-- menus -->
      <template v-for="menu in menus">
        <Menu
          v-if="menu.access && checkTenantMenu(menu.label)"
          :key="menu.id"
          :menu="menu"
          :is-active="menu.label === activeMenu"
          @click="goto(menu.route)"
        />
      </template>

      <!-- ... -->

      <q-space />

      <!-- help -->

      <Menu
        v-if="checkTenant"
        :menu="{
          label: 'help center',
          icon: 'mdi-help-circle-outline',
          activeIcon: 'mdi-help-circle',
        }"
        @click="goto('helpCenter')"
      />

      <!-- ... -->
    </div>
  </q-drawer>
</template>

<script>
import Menu from "./components/Menu.vue";
import { profile } from "@/api/factory.js";
import store from "@/store/index.js";

export default {
  name: "Drawer",

  components: { Menu },

  data() {
    return {
      drawer: true,
      miniDrawer: true,
      menus: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
          icon: "o_dashboard",
          activeIcon: "dashboard",
          access: true,
        },
        {
          id: this.$nano.id(),
          label: "workspaces",
          route: "workspaces-overview",
          icon: "mdi-briefcase-outline",
          activeIcon: "mdi-briefcase",
          access: false,
        },
        {
          id: this.$nano.id(),
          label: "folders",
          route: "repositories-browse",
          icon: "eva-archive-outline",
          activeIcon: "eva-archive",
          access: true,
        },
        {
          id: this.$nano.id(),
          label: "tasks",
          route: "tasks-overview",
          icon: "eva-cube-outline",
          activeIcon: "eva-cube",
          access: false,
        },
        {
          id: this.$nano.id(),
          label: "portals",
          route: "portals-browse",
          icon: "eva-globe-outline",
          activeIcon: "eva-globe",
          access: false,
        },
        {
          id: this.$nano.id(),
          label: "forms",
          route: "forms-overview",
          icon: "mdi-text-box-outline",
          activeIcon: "mdi-text-box",
          access: false,
        },
        {
          id: this.$nano.id(),
          label: "workflows",
          route: "workflows-inbox",
          icon: "o_account_tree",
          activeIcon: "account_tree",
          access: true,
        },
        {
          id: this.$nano.id(),
          label: "reports",
          route: "reports",
          icon: "eva-pie-chart-outline",
          activeIcon: "eva-pie-chart",
          access: false,
        },
        {
          id: this.$nano.id(),
          label: "settings",
          route: "settings",
          icon: "eva-settings-2-outline",
          activeIcon: "eva-settings-2",
          access: false,
        },
      ],
      activeMenu: "",
      profileMenu: [],
    };
  },

  computed: {
    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88" ||
        origin === "http://localhost:8080"
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (
          this.$route.meta.menu === "user" ||
          this.$route.meta.menu === "group" ||
          this.$route.meta.menu === "profile"
        ) {
          this.activeMenu = "settings";
        } else {
          this.activeMenu = this.$route.meta.menu;
        }
      },
    },
  },

  created() {
    if (this.$store.state.session.profile) {
      if (this.$store.state.profileMenus.length === 0) {
        this.getProfileMenu();
      } else {
        this.menus.forEach((menu) => {
          if (!menu.access) {
            menu.access = this.$store.state.profileMenus.find(
              (row) => row[menu.label]
            )?.[menu.label];
          }
        });
      }
    }
  },

  methods: {
    goto(route) {
      this.$router.push({ name: route });
    },

    checkTenantMenu(menu) {
      let origin = location.origin;
      if (
        origin === "http://localhost:80801" ||
        origin === "http://10.16.7.1"
      ) {
        if (
          menu === "dashboard" ||
          menu === "workspaces" ||
          menu === "folders" ||
          menu === "tasks" ||
          menu === "settings"
        ) {
          return true;
        }
        return false;
      }
      return true;
    },

    async getProfileMenu() {
      const { error, payload } = await profile.getProfileList(
        this.$store.state.session.profile.id
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.profileMenu = payload ? JSON.parse(payload.menuOptions) : [];
      this.menus.forEach((menu) => {
        if (!menu.access) {
          menu.access = this.profileMenu.find((row) => row[menu.label])?.[
            menu.label
          ];
        }
      });
      store.commit("setProfileMenu", this.profileMenu);
    },
  },
};
</script>

<style lang="scss" scoped>
#drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--component-bg-color);
}
</style>
