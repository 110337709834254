<template>
  <div id="file-details">
    <BaseScrollbar height="calc(100vh - 120px)">
      <div id="item-overview">
        <div class="version">Version : {{ file.fileVersion }}</div>
        <table>
          <tbody>
            <tr
              v-for="(data, index) in fileData"
              :key="index + '' + data.name"
              class="tableRow"
              @click="textSearchInFile(data.value)"
            >
              <td class="label">{{ data.name }}</td>

              <td v-if="data.type !== 'TABLE'" class="value">
                <div class="row">
                  <div class="col ellipsis">
                    <slot :name="data.name" :value="data.value">
                      {{ data.value || "-" }}
                    </slot>
                  </div>
                  <template
                    v-if="
                      isRetentionRequired && data.type === 'DATE' && data.value
                    "
                  >
                    <div class="col-auto">
                      <BaseActionButton
                        v-tooltip.top="'Notification Settings'"
                        is-flat
                        no-border
                        icon="eva-settings-outline"
                        class="actions"
                        :color="checkRetention(data.id)"
                        @click="showRetention(data.id)"
                      />
                    </div>
                  </template>
                </div>
              </td>
              <td v-else class="value" style="padding: 8px">
                <slot :name="data.name">
                  <BaseActionButton
                    icon="mdi-table-large"
                    is-flat
                    no-border
                    @click="showTable(data.name)"
                  />
                </slot>
              </td>
            </tr>
            <tr class="tableRow">
              <td class="label">Uploaded Date</td>
              <td class="value">
                <div class="row">
                  <div class="col ellipsis">
                    {{ $day.format(file.createdAt) }}
                  </div>
                  <div v-if="isRetentionRequired" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Notification Settings'"
                      is-flat
                      no-border
                      icon="eva-settings-outline"
                      class="actions"
                      :color="checkRetention(0)"
                      @click="showRetention(0)"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </BaseScrollbar>
    <Modal
      v-model="expandTableModal"
      width="60vw"
      height="80vh"
      @input="expandTableModal = false"
    >
      <!-- title -->

      <template #title>{{ tableHeader }}</template>

      <!-- ... -->

      <template #default>
        <div id="tableData">
          <template v-if="tableData.cellvalues">
            <table>
              <tr v-for="(row, idx) in tableData.cellvalues" :key="idx">
                <th
                  :is="cell.kind === 'NoHeader' ? 'td' : 'th'"
                  v-for="cell in row"
                  :key="`r${idx}_c${cell.columnindex}`"
                >
                  {{ cell.cellvalue }}
                </th>
              </tr>
            </table>
          </template>
          <template v-else>
            <table>
              <thead>
                <tr>
                  <th v-for="(value, key) in tableData[0]" :key="key">
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in tableData" :key="index">
                  <td v-for="(value, key) in row" :key="key">
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </template>
    </Modal>
    <Sheet v-model="retentionSettings" width="750px" has-footer @input="reset">
      <template #title> Notification Settings </template>

      <template #default>
        <!-- <BaseScrollbar height="calc(100vh - 219px)"> -->
        <div id="tab-settings" class="row">
          <div class="content">
            <div class="">
              <ValidationObserver ref="retention">
                <div v-show="activeTab === 'GENERAL'">
                  <div class="row q-col-gutter-md">
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="name"
                        :rules="{ required: true }"
                      >
                        <TextField
                          v-model="retention.name"
                          label="name"
                          is-mandatory
                          :error="errors[0]"
                          class="q-mb-md"
                        />
                      </ValidationProvider>
                    </div>
                    <!-- <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Action"
                        :rules="{ required: true }"
                      >
                        <SelectField
                          v-model="retention.action"
                          label="Action"
                          is-mandatory
                          :options="retentionActions"
                          :error="errors[0]"
                          class="q-mb-md"
                        />
                      </ValidationProvider>
                    </div> -->
                  </div>

                  <SingleChoiceField
                    v-model="notificationDate"
                    is-mandatory
                    label="Set Notification Date"
                    :options-per-line="notificationDateOptions.length"
                    :options="notificationDateOptions"
                    class="q-mb-md"
                  />

                  <template v-if="notificationDate === 'field_date'">
                    <FormFieldLabel
                      :label="getFieldDateLabel"
                      class="q-mb-lg"
                    />
                    <BaseSeparator class="q-mb-md" />
                  </template>

                  <template v-if="notificationDate === 'specific_date'">
                    <FormFieldLabel
                      label="Define a specific date for document notification, activating automatic reminders when the chosen date is reached."
                      class="q-mb-lg"
                      transform=""
                    />
                    <BaseSeparator class="q-mb-md" />

                    <ValidationProvider
                      v-slot="{ errors }"
                      name="notification date"
                      :rules="dateValidation"
                    >
                      <DateField
                        v-model="retention.actionDate"
                        label="notification date"
                        class="q-mb-md"
                        is-mandatory
                        validation
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </template>

                  <div
                    v-if="notificationDate === 'future_date'"
                    class="row q-col-gutter-md"
                  >
                    <div class="col-12">
                      <FormFieldLabel
                        label="Allows to schedule a future expiry date
                based on an existing date, which prompts automatic reminders
                when due"
                        class="q-mb-md"
                        transform=""
                      />
                      <BaseSeparator />
                    </div>
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Duration"
                        :rules="{ required: true }"
                      >
                        <CounterField
                          v-model="retention.duration"
                          label="Duration"
                          :error="errors[0]"
                          class="q-mb-md"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Duration Type"
                        :rules="{ required: true }"
                      >
                        <SelectField
                          v-model="retention.durationType"
                          label="Duration Type"
                          :options="durationTypes"
                          :error="errors[0]"
                          class="q-mb-md"
                        />
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row q-col-gutter-md">
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="user"
                        :rules="{
                          required: retention.notification.group.length === 0,
                        }"
                      >
                        <MultiSelectField
                          v-model="retention.notification.user"
                          label="users"
                          :options="userList"
                          class="q-m-md"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="group"
                        :rules="{
                          required: retention.notification.user.length === 0,
                        }"
                      >
                        <MultiSelectField
                          v-model="retention.notification.group"
                          label="groups"
                          :options="groupList"
                          class="q-m-md"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>

                  <FormFieldError v-if="dateError" :error="dateError" />
                </div>
                <!-- <CheckboxField
                  v-model="notify"
                  label="Notify via Email"
                  description=""
                  class="q-mb-md"
                /> -->
                <template v-if="activeTab === 'NOTIFICATION'">
                  <!-- <div class="row q-col-gutter-md">
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Before Counts"
                        :rules="{ required: notify }"
                      >
                        <CounterField
                          v-model="retention.notification.duration"
                          label="Before the retention policy"
                          :is-mandatory="notify"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Duration Type"
                        :rules="{ required: notify }"
                      >
                        <SelectField
                          v-model="retention.notification.durationType"
                          label="Duration Type"
                          :is-mandatory="notify"
                          :options="durationTypes"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row q-col-gutter-md">
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="user"
                        :rules="{
                          required: retention.notification.group.length === 0,
                        }"
                      >
                        <MultiSelectField
                          v-model="retention.notification.user"
                          label="users"
                          :options="userList"
                          class="q-mt-md"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="group"
                        :rules="{
                          required: retention.notification.user.length === 0,
                        }"
                      >
                        <MultiSelectField
                          v-model="retention.notification.group"
                          label="groups"
                          :options="groupList"
                          class="q-mt-md"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </div> -->

                  <FormFieldLabel label="Mail Subject" />
                  <TextEditor
                    v-model="retention.notification.mailSubject"
                    :hide-tool-bar="false"
                    :form-columns="fieldColumns"
                    class="mail-class"
                  ></TextEditor>

                  <FormFieldLabel label="Mail Content" class="q-my-md" />
                  <TextEditor
                    v-model="retention.notification.mailBody"
                    :form-columns="fieldColumns"
                    class="mail-class"
                  ></TextEditor>
                </template>
              </ValidationObserver>
            </div>
          </div>
          <!-- tabs -->

          <div class="tabs">
            <BaseActionButton
              v-for="tab in tabs"
              :key="tab.id"
              v-tooltip.left="tab.label"
              :is-flat="activeTab !== tab.value"
              :icon="tab.icon"
              :color="activeTab === tab.value ? 'primary' : 'gray'"
              class="q-mb-md"
              @click="activeTab = tab.value"
            />
          </div>

          <!-- ... -->
        </div>
      </template>

      <template #footer>
        <BaseButton is-flat label="clear" class="q-mr-sm" @click="reset" />

        <BaseButton label="save" @click="saveRetention" />
      </template>
    </Sheet>
  </div>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import { repository, user, group } from "@/api/factory.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TextEditor from "@/components/common/text-editor-with-columns/TextEditor";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "FileDetails",

  components: {
    Modal,
    Sheet,
    CounterField,
    MultiSelectField,
    TextField,
    SelectField,
    ValidationObserver,
    ValidationProvider,
    CheckboxField,
    FormFieldLabel,
    TextEditor,
    SingleChoiceField,
    DateField,
    FormFieldError,
  },

  props: {
    file: {
      type: Object,
      required: true,
    },

    repositoryField: {
      type: Array,
      required: true,
    },

    fileData: {
      type: Array,
      required: true,
    },

    isRetentionRequired: {
      type: Boolean,
      default: false,
    },

    retentionList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expandTableModal: false,
      tableHeader: "",
      tableData: [],
      retentionFieldId: 0,
      retentionSettings: false,
      retention: {
        id: 0,
        repositoryId: this.file.repositoryId,
        itemId: this.file.id,
        name: "",
        fieldId: "",
        duration: 0,
        durationType: "Day",
        conditionJson: "",
        action: "",
        actionDate: "",
        notification: {
          duration: 0,
          durationType: "Day",
          user: [],
          group: [],
          mailSubject: {
            text: "",
            content: "",
          },
          mailBody: {
            text: "",
            content: "",
          },
        },
      },
      durationTypes: [
        {
          id: this.$nano.id(),
          label: "Year",
          value: "Year",
        },
        {
          id: this.$nano.id(),
          label: "Month",
          value: "Month",
        },
        {
          id: this.$nano.id(),
          label: "Day",
          value: "Day",
        },
      ],
      userList: [],
      groupList: [],
      retentionActions: [
        {
          id: this.$nano.id(),
          label: "No Action",
          value: "NO_ACTION",
        },
        {
          id: this.$nano.id(),
          label: "Hide",
          value: "HIDE",
        },
        {
          id: this.$nano.id(),
          label: "Move to Trash",
          value: "TRASH",
        },
        {
          id: this.$nano.id(),
          label: "Permanent Delete",
          value: "DELETE",
        },
      ],
      notify: true,

      notificationDate: "field_date",
      activeTab: "GENERAL",
      tabs: [
        {
          id: this.$nano.id(),
          label: "general",
          value: "GENERAL",
          icon: "mdi-tune",
        },
        {
          id: this.$nano.id(),
          label: "notification",
          value: "NOTIFICATION",
          icon: "mdi-bell-outline",
        },
      ],
      dateError: "",
    };
  },

  computed: {
    fieldColumns() {
      let fields = [
        {
          id: this.$nano.id(),
          label: "Uploaded Date",
          value: "#UPLOADEDDATE#",
          display: "",
          color: "#643094",
          hasDefault: true,
        },
        {
          id: this.$nano.id(),
          label: "Uploaded By",
          value: "#UPLOADBY#",
          display: "",
          color: "#643094",
          hasDefault: true,
        },
      ];
      let columns = this.repositoryField.map((column) => ({
        id: column.id,
        label: column.name,
        value: column.id,
        hasDefault: false,
      }));

      return [...fields, ...columns];
    },

    getFieldDateLabel() {
      if (this.retention.fieldId) {
        let field = this.repositoryField.find(
          (item) => item.id === this.retention.fieldId
        );
        return `Set a Document's expiry based on
                    an ${field.name}, which triggers auto-reminders when due.`;
      }
      return "";
    },

    notificationDateOptions() {
      if (this.retention.fieldId) {
        return [
          {
            id: this.$nano.id(),
            label: "Use Field Date",
            value: "field_date",
          },
          {
            id: this.$nano.id(),
            label: "Use Specific Date",
            value: "specific_date",
          },
          {
            id: this.$nano.id(),
            label: "Set Future Date from Field Date",
            value: "future_date",
          },
        ];
      } else {
        return [
          {
            id: this.$nano.id(),
            label: "Use Specific Date",
            value: "specific_date",
          },
          {
            id: this.$nano.id(),
            label: "Set Future Date from Field Date",
            value: "future_date",
          },
        ];
      }
    },

    dateValidation() {
      const rules = {};

      if (this.notificationDate === "specific_date") {
        rules.required = true;
      }

      let minDate = "";
      if (this.retention.fieldId) {
        let field = this.fileData.find(
          (item) => item.id === this.retention.fieldId
        );

        minDate = field.value;
      } else {
        minDate = this.$day.format(this.file.createdAt, false);
      }

      if (minDate) {
        rules.minDate = minDate;
      }
      return rules;
    },
  },

  watch: {
    file: {
      immediate: true,
      deep: true,
      async handler() {
        if (!this.file) {
          return;
        }
      },
    },

    notificationDate() {
      if (this.notificationDate) {
        this.dateError = "";
      }
    },
  },

  mounted() {
    this.getUserList();
    this.getGroupList();
  },

  methods: {
    textSearchInFile(fieldValue) {
      localStorage.setItem("findText", fieldValue);
    },

    showTable(name) {
      let data = this.fileData.filter((item) => item.name === name);
      this.tableHeader = data[0].name;
      this.tableData = JSON.parse(data[0].value);
      this.expandTableModal = true;
    },

    checkRetention(id) {
      if (this.retentionList.filter((row) => row.fieldId === id).length) {
        return "orange";
      }
      return "";
    },

    showRetention(id) {
      this.retentionFieldId = id;
      this.reset();
      let rule = this.retentionList.find((row) => row.fieldId === id);
      if (rule) {
        this.retention.id = rule.id;
        this.retention.name = rule.name;
        this.retention.duration = rule.duration;
        this.retention.durationType = rule.durationType;
        this.retention.action = rule.action;
        this.retention.actionDate = rule.actionDate;
        this.notificationDate = "field_date";
        if (this.retention.duration) {
          this.notificationDate = "future_date";
        } else if (this.retention.actionDate) {
          this.notificationDate = "specific_date";
        }
        this.retention.notification = JSON.parse(rule.notifyJson);
        if (rule.notifyJson) {
          this.notify = true;
        }
        // console.log(this.retention.notification, JSON.parse(rule.notifyJson));
        if (this.retention.notification.mailSubject) {
          ///
        } else {
          this.retention.notification.mailSubject = {
            text: "",
            content: "",
          };
          this.retention.notification.mailBody = {
            text: "",
            content: "",
          };
        }
      }
      this.retention.fieldId = id;
      if (!rule) {
        if (this.retention.fieldId) {
          this.notificationDate = "field_date";
        } else {
          this.notificationDate = "future_date";
        }
      }
      this.retentionSettings = true;
    },

    reset() {
      this.activeTab = "GENERAL";
      this.retentionSettings = false;
      this.retention.name = "";
      this.retention.fieldId = "";
      this.retention.action = "NO_ACTION";
      this.retention.actionDate = "";
      this.retention.durationType = "Day";
      this.retention.duration = 0;
      this.retention.notification = {
        duration: 0,
        durationType: "Day",
        user: [],
        group: [],
        mailSubject: {
          text: "",
          content: "",
        },
        mailBody: {
          text: "",
          content: "",
        },
      };
      this.dateError = "";
      this.notify = true;
    },

    async getUserList() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.userList =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value,
          value: user.id,
        }));
    },

    async getGroupList() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
          // value: parseInt(group.id, 10),
        }));
    },

    async saveRetention() {
      if (this.notificationDate === "field_date") {
        this.retention.actionDate = "";
        this.retention.duration = 0;
        this.retention.durationType = "Day";
      } else if (this.notificationDate === "specific_date") {
        this.retention.duration = 0;
        this.retention.durationType = "Day";
      } else if (this.notificationDate === "future_date") {
        this.retention.actionDate = "";
      }
      let date = "";
      if (!this.retention.fieldId) {
        date = this.$day.format(this.file.createdAt, false);
      } else {
        let field = this.fileData.find(
          (item) => item.id === this.retention.fieldId
        );

        date = field.value;
      }
      let futureDate = "";
      let selectDate = "";
      if (this.notificationDate === "future_date") {
        if (this.retention.durationType === "Day") {
          futureDate = this.$day.addDate(date, this.retention.duration);
        }
        if (this.retention.durationType === "Month") {
          futureDate = this.$day.addMonth(date, this.retention.duration);
        }
        if (this.retention.durationType === "Year") {
          futureDate = this.$day.addYear(date, this.retention.duration);
        }

        if (this.$day.isDateGreater(date, this.$day.newDate())) {
          selectDate = date;
        } else {
          selectDate = this.$day.newDate();
        }
      } else if (this.notificationDate === "specific_date") {
        futureDate = this.retention.actionDate;
      } else {
        futureDate = date;
      }
      // console.log(futureDate);
      // console.log(date, this.$day.newDate());

      if (futureDate >= this.$day.newDate()) {
        // console.log(true);
        this.dateError = "";
      } else {
        // console.log(false);
        if (this.notificationDate === "field_date") {
          this.dateError = `Its completed date. please select any one option in two options`;
        } else {
          this.dateError = `Please select any date after (${selectDate}) this date`;
        }
        return;
      }

      const condition = await this.$refs.retention.validate();

      if (!condition) {
        return;
      }

      if (this.notify) {
        this.retention.notifyJson = JSON.stringify(this.retention.notification);
      } else {
        this.retention.notifyJson = "";
      }
      this.$store.commit("showLoadingBar");
      if (
        this.retention.id &&
        this.retention.fieldId === this.retentionFieldId
      ) {
        const { error } = await repository.updateRetention(
          this.retention.id,
          this.retention
        );
        if (error) {
          this.$alert.error(error);
          return;
        }

        this.$alert.success("Notification rule updated succesfully");
      } else {
        const { error } = await repository.addRetention(this.retention);
        if (error) {
          this.$alert.error(error);
          return;
        }

        this.$alert.success("Notification rule added succesfully");
      }
      this.$store.commit("hideLoadingBar");
      this.$emit("refresh");
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
#file-details {
  .field-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    .bot-recommendations {
      margin: 30px 0px 0px 8px;
    }
  }

  .version {
    padding: 12px;
    background: var(--divider-color);
    font-weight: 500;
  }

  #action-btn.actions {
    width: 22px !important;
    height: 22px !important;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
}

#item-overview {
  padding: 16px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td {
      vertical-align: top;
      padding: 16px 8px;
      overflow: hidden;
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .label {
    color: var(--icon-color);
    width: 200px;
  }

  .value {
    font-weight: 500;
  }

  .tableRow:hover {
    background: $secondary-1;
    cursor: pointer;
  }

  .label:hover,
  .value:hover {
    white-space: normal !important;
  }
}

#modal #tableData {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

.mail-class {
  margin: 0px !important;
}

#tab-settings {
  // .field {
  //   margin: 16px 16px 24px 16px;
  // }

  .content {
    flex: 1;
    padding: 16px;
    border-right: 1px solid var(--divider-color);
  }

  .tabs {
    width: 68px;
    padding: 16px;
  }
}

// .theme-light {
//   #item-overview {
//     tr:nth-child(even) {
//       background-color: rgba($color: $gray-6, $alpha: 0.06);
//     }
//   }
// }

// .theme-dark {
//   #item-overview {
//     tr:nth-child(even) {
//       background-color: rgba($color: $gray-5, $alpha: 0.06);
//     }
//   }
// }
</style>
