<template>
  <div class="relative-position cursor-pointer" @click="homePage">
    <div id="legend">
      <!-- <img src="@/assets/logo/mark-white.png" /> -->
      <img src="https://demo.ezofis.com/Logo/mark-white.png" />
    </div>

    <div id="legend-tail"></div>
  </div>
</template>

<script>
export default {
  name: "Legend",

  methods: {
    homePage() {
      if (this.$store.state.session.profile) {
        if (this.$store.state.profileMenus.length) {
          if (
            this.$store.state.profileMenus.find((row) => row.workspaces)?.Menu[
              "Overview"
            ]
          ) {
            if (this.$route.name !== "repositories-browse") {
              this.$router.push({ name: "repositories-browse" });
            }
            return;
          }
        }
      }
      if (this.$route.name !== "workflows-overview") {
        this.$router.push({ name: "workflows-overview" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#legend {
  border-radius: 0px 48px 48px 0px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  background-color: var(--primary);

  img {
    height: 20px;
  }
}

#legend-tail::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -36px;
  height: 36px;
  width: 12px;
  border-top-left-radius: 24px;
  box-shadow: 0 -12.5px 0 0 var(--primary);
}
</style>
