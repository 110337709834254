<template>
  <div id="account">
    <!-- <div id="company-logo">
      <img src="@/assets/google.png" alt="logo" />
    </div> -->

    <div id="user-avatar" @click="profileSheet = true">
      <img :src="imageURL()" alt="avatar" />
    </div>

    <Sheet
      v-model="profileSheet"
      width="25vw"
      :has-footer="false"
      @input="profileSheet = false"
    >
      <!-- title -->

      <template #title> Profile </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper" class="profileData">
        <!-- profile picture -->
        <div class="q-mt-sm q-mb-sm">
          <div class="text-center">
            <div class="profile-avatar">
              <!-- <img src="@/assets/avatar/avatar.png" alt="avatar" /> -->
              <img :src="imageURL()" alt="avatar" />
            </div>

            <div class="text-weight-bold q-mt-md">
              <template v-if="isAdUser">
                {{ $store.state.session.firstName }}
                {{ $store.state.session.lastName }}
              </template>
              <template v-else>
                {{ $store.state.session.email }}
              </template>
            </div>
            <div class="text-caption">
              {{ $store.state.session.role }}
            </div>
          </div>
        </div>
        <!-- ... -->

        <!-- menus -->
        <div v-for="(group, idx) in menus" :key="idx">
          <template v-for="menu in group">
            <div
              v-if="menu.required || !checkTenant"
              :key="menu.label"
              class="row items-center hoverable"
              style="height: 50px"
              @click="menu.action"
            >
              <!-- menu icon -->
              <div
                class="col-2 row items-center justify-center"
                style="color: var(--icon-color)"
              >
                <q-icon :name="menu.icon" size="20px" />
              </div>
              <!-- ... -->

              <!-- menu label -->
              <div class="col text-capitalize">
                {{ menu.label }}
              </div>
              <!-- ... -->
            </div>
          </template>
          <q-separator
            v-if="idx !== menus.length - 1"
            inset
            color="grey-2"
          ></q-separator>
        </div>
        <!-- ... -->
      </div>
      <!-- ... -->
    </Sheet>
    <Modal
      v-model="changePasswordModal"
      width="30vw"
      height="70vh"
      has-footer
      @input="changePasswordModal = false"
    >
      <!-- title -->

      <template #title> Change Password</template>

      <!-- ... -->

      <template #default>
        <div id="changePassword">
          <!-- password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="password"
              label="password"
              is-mandatory
              :tooltip="_passwordHint"
              :error="errors[0]"
              class="q-mb-lg"
            />
          </ValidationProvider>

          <!-- ... -->

          <!-- confirm password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="confirmPassword"
              label="confirm password"
              is-mandatory
              :is-readonly="isLoading"
              :tooltip="_passwordHint"
              :error="errors[0]"
              class="q-mb-lg"
            />
          </ValidationProvider>

          <!-- ... -->
        </div>
      </template>

      <template #footer>
        <BaseButton label="Update" color="primary" @click="updatePassword" />
      </template>
    </Modal>

    <Modal
      v-model="changeIdentityModal"
      width="30vw"
      height="70vh"
      has-footer
      @input="changeIdentityModal = false"
    >
      <!-- title -->

      <template #title> Change Identity</template>

      <!-- ... -->

      <template #default>
        <div id="changePhoto">
          <ValidationProvider
            v-slot="{ errors }"
            name="upload image"
            :rules="{ required: true }"
          >
            <ImageField
              v-model="uploadImage"
              label="upload image"
              :is-mandatory="true"
              :error="errors[0]"
            />
          </ValidationProvider>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Remove"
          color="red"
          class="q-mr-md"
          @click="removeImage"
        />
        <BaseButton label="Update" color="primary" @click="updateImage" />
      </template>
    </Modal>
  </div>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import Modal from "@/components/common/popup/Modal.vue";
import { ValidationProvider } from "vee-validate";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import { passwordHint } from "@/helpers/password-hint.js";
import ImageField from "@/components/common/form/image-field/ImageField.vue";
import { user } from "@/api/factory.js";
export default {
  name: "Account",

  components: { Sheet, Modal, ValidationProvider, PasswordField, ImageField },

  data() {
    return {
      profileSheet: false,
      menus: [
        [
          {
            icon: "eva-person-outline",
            label: "change identity",
            action: this.changeIdentity,
            required: false,
          },
          {
            icon: "eva-lock-outline",
            label: "change password",
            action: this.changePassword,
            required: false,
          },
          // {
          //   icon: "eva-book-open-outline",
          //   label: "view profile",
          //   action: this.viewProfile,
          // },
          {
            icon: "mdi-clipboard-text-outline",
            label: "Terms of Use",
            action: this.showTermsOfUse,
            required: true,
          },
          {
            icon: "eva-shield-outline",
            label: "Privacy Policy",
            action: this.showPrivacyPolicy,
            required: true,
          },
        ],
        [
          {
            icon: "eva-log-out",
            label: "Log Out",
            action: this.logOut,
            required: true,
          },
        ],
      ],
      password: "",
      confirmPassword: "",
      changePasswordModal: false,
      changeIdentityModal: false,
      uploadImage: "",
    };
  },

  computed: {
    _passwordHint() {
      return passwordHint;
    },

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return true;
      }
      return false;
    },

    isAdUser() {
      let origin = location.origin;
      if (origin === "http://172.16.1.118") {
        return true;
      }
      return false;
    },
  },

  methods: {
    logOut() {
      window.localStorage.clear();
      this.$router.push({ name: "signIn" });
    },

    changePassword() {
      this.changePasswordModal = true;
    },

    showTermsOfUse() {
      window.open("https://www.ezofis.com/privacy-policy/");
    },

    showPrivacyPolicy() {
      window.open("https://www.ezofis.com/privacy-policy/");
    },

    changeIdentity() {
      this.changeIdentityModal = true;
    },

    viewProfile() {
      this.showUserSheet();
    },

    imageURL() {
      return `${process.env.VUE_APP_API_URL}/user/avatar/${
        this.$store.state.session.tenantId
      }/${this.$store.state.session.id}?t=${new Date().getTime()}`;
    },

    async updatePassword() {
      if (this.password === this.confirmPassword) {
        this.$store.commit("showLoadingBar");
        let input = {
          password: this.password,
        };
        const { error } = await user.updateUser(
          this.$store.state.session.id,
          input
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$alert.success(`Password updated`);
        this.changePasswordModal = false;
        this.password = "";
        this.confirmPassword = "";
      } else {
        this.$alert.warning("password not matched");
      }
    },

    async updateImage() {
      this.$store.commit("showLoadingBar");
      let input = {
        base64file: this.uploadImage.split(",")[1],
        fileType: "png",
      };
      const { error } = await user.uploadImage(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$store.commit("hideLoadingBar");
      this.changeIdentityModal = false;
      this.uploadImage = "";
    },

    async removeImage() {
      const { error } = await user.removeImage(
        this.$store.state.session.tenantId,
        this.$store.state.session.id
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.changeIdentityModal = false;
      this.uploadImage = "";
    },
  },
};
</script>

<style lang="scss" scoped>
#account {
  // display: flex;
  // align-items: center;
  // background-color: var(--body-bg-color);
  // border-radius: 4px;
  // padding: 4px 8px;

  &:hover {
    cursor: pointer;
  }

  #company-logo {
    height: 24px;
    margin-right: 16px;
  }

  #user-avatar {
    height: 40px;
  }
}

#sheet {
  .profile-avatar {
    height: 150px;
  }

  .hoverable:hover {
    cursor: pointer;
    background-color: #eee;
  }
}
</style>
