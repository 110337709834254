<template>
  <div>
    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="
        selectedProcess.processId && attachmentList.length ? true : false
      "
      :upload="
        selectedNode === 'inbox' ||
        selectedNode === 'common' ||
        selectedNode === 'payment' ||
        initiateProcess ||
        isPortal
      "
      no-padding
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 15 seconds
        </div>
        <BaseActionButton
          v-if="selectedProcess.processId"
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments()"
        />
      </template>

      <template
        v-if="
          selectedNode === 'inbox' ||
          selectedNode === 'common' ||
          selectedNode === 'payment' ||
          initiateProcess ||
          isPortal
        "
        #upload
      >
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template
        v-if="selectedProcess.processId && attachmentList.length"
        #subActions
      >
        <CheckboxField
          v-model="selectAll"
          class="col-auto q-pt-sm q-pb-sm"
          @input="fileSelectAll"
        />
        Select All

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-tooltip.top="'merge documents (only PDF)'"
          :is-disabled="selectedFileCount > 1 && isSelectedPDF ? false : true"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          @click="documentMerge"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            selectedNode === 'inbox' ||
            selectedNode === 'common' ||
            selectedNode === 'payment' ||
            initiateProcess ||
            isPortal
              ? 'calc(100vh - 200px)'
              : ''
          "
        >
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template
              v-if="row.name && (!row.hasOwnProperty('visible') || row.show)"
            >
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="row.uid">
                <template v-for="file in attachmentFilterCheckList(row.name)">
                  <div :key="file.id" class="attachmentList">
                    <div class="title row">
                      <div v-if="selectedProcess.processId" class="col-auto">
                        <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                        <template v-else>
                          <BaseIcon
                            :name="
                              file.checked
                                ? 'eva-checkmark-circle-2'
                                : 'eva-radio-button-off-outline'
                            "
                            color="secondary"
                            class="q-mr-sm"
                            @click="file.checked = !file.checked"
                          />
                        </template>
                      </div>
                      <div v-else>
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                      </div>
                      <div v-if="file.formJsonId" class="col-auto">
                        <BaseIcon
                          v-tooltip:green.top="getLabel(file.formJsonId)"
                          name="mdi-form-select"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        <FileIcon
                          :mime-type="fileType(file.name)"
                          class="mini-avatar q-mr-sm"
                        />
                      </div>
                      <div class="col ellipsis" @click="openFile(file)">
                        {{ file.name }}
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Download'"
                          icon="eva-download-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Download')"
                        />
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Print'"
                          icon="eva-printer-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Print')"
                        />
                      </div>
                    </div>

                    <div class="meta row">
                      <div class="col row">
                        <!-- created at -->

                        <div>{{ $day.format(file.createdAt) }}</div>

                        <!-- ... -->

                        <!-- created by -->

                        <template v-if="file.createdBy">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ file.createdByEmail }}</div>
                        </template>

                        <!-- ... -->

                        <!-- size -->

                        <template v-if="file.size">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ fileSize(file.size) }}</div>
                        </template>

                        <!-- ... -->
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Metadata'"
                          icon="eva-file-text-outline"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="getFileData(file)"
                        />
                      </div>
                      <template v-if="false">
                        <template
                          v-if="
                            selectedNode === 'inbox' ||
                            selectedNode === 'common' ||
                            selectedNode === 'payment' ||
                            initiateProcess
                          "
                        >
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'delete'"
                              icon="eva-trash-2-outline"
                              color="red"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="attachmentDelete(file)"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'Re-Upload'"
                              icon="mdi-file-upload"
                              color="primary"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="
                                {
                                  reuploadFile = file;
                                  reupload(file.name);
                                }
                              "
                            />
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            ((selectedNode === 'inbox' ||
                              selectedNode === 'common' ||
                              selectedNode === 'payment') &&
                              file.createdBy === $store.state.session.id) ||
                            initiateProcess
                          "
                        >
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'delete'"
                              icon="eva-trash-2-outline"
                              color="red"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="attachmentDelete(file)"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'Re-Upload'"
                              icon="mdi-file-upload"
                              color="primary"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="
                                {
                                  reuploadFile = file;
                                  reupload(file.name);
                                }
                              "
                            />
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <template v-if="isCheckList(file.name)">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <template v-if="file.loading">
                    <BaseIcon
                      name="mdi-loading"
                      color="secondary"
                      class="q-mr-sm loading"
                    />
                  </template>
                  <div v-if="selectedProcess.processId" class="col-auto">
                    <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->

                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <div v-if="file.formJsonId" class="col-auto">
                    <BaseIcon
                      v-tooltip:green.top="getLabel(file.formJsonId)"
                      name="mdi-form-select"
                      color="primary"
                      class="q-mr-sm"
                    />
                  </div>
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFile(file)">
                    {{ file.name }}
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      icon="eva-download-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Download')"
                    />
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Print'"
                      icon="eva-printer-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Print')"
                    />
                  </div>
                </div>

                <div class="meta row">
                  <div class="col row">
                    <!-- created at -->

                    <div>{{ $day.format(file.createdAt) }}</div>

                    <!-- ... -->

                    <!-- created by -->

                    <template v-if="file.createdBy">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ file.createdByEmail }}</div>
                    </template>

                    <!-- ... -->

                    <!-- size -->

                    <template v-if="file.size">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ fileSize(file.size) }}</div>
                    </template>

                    <!-- ... -->
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Metadata'"
                      icon="eva-file-text-outline"
                      color="secondary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="getFileData(file)"
                    />
                  </div>
                  <div
                    v-if="
                      ((selectedNode === 'inbox' ||
                        selectedNode === 'common' ||
                        selectedNode === 'payment') &&
                        file.createdBy === $store.state.session.id) ||
                      initiateProcess
                    "
                    class="col-auto"
                  >
                    <BaseActionButton
                      v-tooltip.top="'delete'"
                      icon="eva-trash-2-outline"
                      color="red"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="attachmentDelete(file)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="workflow.repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>

    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>

    <Sheet
      v-model="mailAttachmentSheet"
      width="30vw"
      no-padding
      @input="mailAttachmentSheet = false"
    >
      <!-- title -->

      <template #title> Mail Attachments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 200px)">
          <template v-for="file in mailAttachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openAttachmentFile(file)">
                  {{ file.name }}
                </div>
              </div>

              <div class="meta">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- size -->

                <template v-if="file.size">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ fileSize(file.size) }}</div>
                </template>

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <MetaDataEdit
      :value="showMetaDataEditModal"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      :title="metaDataTitle || 'Index'"
      :columns="columns"
      :modal-value="workflowModel"
      :upload-filename="uploadFilename"
      :meta-data-fields="metaDataModal"
      @close="closeMetaDataEditModal"
      @save="saveMetaData"
    />
  </div>
</template>
<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import EmailSharing from "@/components/common/EmailSharing.vue";
import { workflow, menu, repository, uploadAndIndex } from "@/api/factory.js";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
  fileSupport,
} from "@/helpers/file-format.js";
import FileDetails from "@/views/workflows/views/workflows-inbox/components/FileDetails.vue";
import axios from "axios";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import { lowerCase } from "lodash-es";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "Attachments",

  components: {
    Sheet,
    EmailSharing,
    FileIcon,
    FileDetails,
    MetaDataEdit,
    CheckboxField,
    ListItem,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    selectedProcess: {
      type: Object,
      default: () => {},
    },

    workflow: {
      type: Object,
      default: () => {},
    },

    selectedNode: {
      type: String,
      required: true,
    },

    initiateProcess: {
      type: Boolean,
      default: false,
    },

    formCheckList: {
      type: Boolean,
      default: false,
    },

    checkList: {
      type: Array,
      default: () => [],
    },

    wform: {
      type: Object,
      default: () => {},
    },

    repositoryDetails: {
      type: Object,
      default: () => {},
    },

    workflowModel: {
      type: Object,
      default: () => {},
    },

    formUploadFiles: {
      type: Array,
      default: () => [],
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    repositoryField: {
      type: Array,
      default: () => [],
    },

    columns: {
      type: Array,
      default: () => [],
    },

    attachments: {
      type: Array,
      default: () => [],
    },

    workflowId: {
      type: Number,
      required: true,
    },

    isPortal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      attachmentSheet: false,
      attachmentList: [],
      selectAll: false,
      emailSharingSheet: false,
      selectedFileList: [],
      fileDetailsSheet: false,
      fileData: [],
      checkListRowIndex: 0,
      selectedFile: {},
      mailTransactionList: [],
      mailAttachmentSheet: false,
      mailAttachmentList: [],
      fileSheet: false,
      watchViewerClose: null,
      reuploadFile: {},
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      uploadFilename: "",
      showMetaDataEditModal: false,
      selectedUploadFile: {},
      documentLoad: null,
      documentLoadList: [],
      metaDataModal: {},
      metaDataTitle: "",
      selectedCheckListName: "",
      // checkList: [],
      // formCheckList: false,
    };
  },

  computed: {
    selectedFileCount() {
      this.clearAttach();
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    isSelectedPDF() {
      let filePDF = true;
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() !== "pdf") {
          filePDF = false;
        }
      });
      return filePDF;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          // this.getAttachments();
          this.attachmentSheet = true;
          this.attachmentList = this.attachments;
          if (this.attachmentSheet && this.selectedProcess.processId) {
            this.getAttachments();
            if (this.selectedProcess.flowStatus === "0") {
              if (this.attachmentList.length) {
                let index = this.attachmentList.length - 1;
                this.getFileData(this.attachmentList[index], "file");
              }
              this.attachmentLoad = setInterval(() => {
                this.getAttachments();
              }, 15000);
            }
          } else {
            // this.getAttachments();
            clearInterval(this.attachmentLoad);
          }
        }
      },
    },
  },

  methods: {
    async getAttachments(workflowId = 0, processId = 0) {
      // this.tabs.find((tab) => {
      //   if (tab.value === "ATTACHMENTS") {
      //     tab.count = 0;
      //   }
      // });
      let count = this.attachmentList.length;

      if (!workflowId && !processId) {
        workflowId = this.workflowId;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getAttachments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          //clearInterval(this.attachmentLoad);
        }
        // this.tabs.find((tab) => {
        //   if (tab.value === "ATTACHMENTS") {
        //     tab.count = this.attachmentList.length;
        //   }
        // });
        this.$emit("attachmentCount", this.attachmentList.length);
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename || row.name === filename.split("_")[0]) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
        let origin = location.origin;
        if (
          this.isTenantArmgroup() ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)
        ) {
          if (
            this.selectedProcess.attachmentCount !== this.attachmentList.length
          ) {
            // this.formCheckList = true;
            this.$emit("update:formCheckList", true);
          }
        }
      }
    },

    isTenantArmgroup() {
      let origin = location.origin;
      if (
        (origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          origin === "http://localhost:8080") &&
        this.$store.state.session.tenantId === 3
      ) {
        return true;
      }
      return false;
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    async documentMerge() {
      let fileIds = [];
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() === "pdf") {
          fileIds.push(item.id);
        }
      });
      let input = {
        ids: fileIds,
      };
      const { error } = await workflow.documentMerge(
        this.workflowId,
        this.selectedProcess.processId,
        this.selectedProcess.transactionId,
        this.workflow.repositoryId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Document merge process initiated");
    },

    async documentShare() {
      this.selectedFileList = [];
      this.attachmentList.forEach((item) => {
        if (item.checked) {
          this.selectedFileList.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.emailSharingSheet = true;
    },

    clearAttach() {
      // this.selectedFileForAttach = 0;
      // this.attachComment = false;
      // this.notifyInitiator = false;
      console.log("clear");
    },

    attachTemplate(uid) {
      this.checkListRowIndex = uid;
      this.$refs.attachments.click();
      this.selectedCheckListName = this.checkList.find(
        (item) => uid == item.uid
      ).name;
    },

    reupload(filename) {
      filename = filename.substr(0, filename.lastIndexOf(".")).split("_")[0];
      this.checkListRowIndex = this.checkList.find(
        (row) => row.name === filename
      )?.uid;
      this.$refs.attachments.click();
    },

    attachmentFilterCheckList(name) {
      return this.attachmentList.filter(
        (row) =>
          row.name.substring(0, row.name.lastIndexOf(".")) === name ||
          row.name.substring(0, row.name.lastIndexOf(".")).split("_")[0] ===
            name
      );
    },

    isCheckList(name) {
      let list = this.checkList.filter(
        (row) =>
          row.name === name.substring(0, name.lastIndexOf(".")) ||
          row.name === name.substring(0, name.lastIndexOf(".")).split("_")[0]
      );
      if (list.length) {
        return false;
      }
      return true;
    },

    showMailAttachments(mail) {
      this.mailAttachmentList = [];
      let files = mail.attachmentJson.split(",");
      files.forEach((file) => {
        let filename = file.split("\\").pop();
        this.mailAttachmentList.push({
          id: this.$nano.id(),
          mailId: mail.id,
          name: filename,
          createdAt: mail.createdAt,
        });
      });
      this.mailAttachmentSheet = true;
    },

    openAttachmentFile(file) {
      let link = `${process.env.VUE_APP_API_URL}/client/viewAttachment/${this.$store.state.session.tenantId}/${file.mailId}/${file.name}`;
      window.open(link, "_blank");
    },

    getLabel(id) {
      let controlLabel = "";
      let formJson = this.wform.formJson
        ? JSON.parse(this.wform.formJson)
        : this.wform;
      const panels = [...formJson.panels, ...formJson.secondaryPanels];

      if (!panels.length) {
        return;
      }
      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }
      fields.forEach((field) => {
        const column = field.settings.specific.tableColumns.find(
          (column) => column.id === id
        );
        if (column) {
          controlLabel = column.label;
        } else {
          if (field.id === id) {
            controlLabel = field.label;
            return;
          }
        }
      });
      return controlLabel;
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    openFile(fileObj) {
      if (!fileObj.loading) {
        this.selectedFile = fileObj;
        if (this.isTenantArmgroup()) {
          window.open(this.fileLink());
        } else {
          this.fileSheet = true;
        }
      } else {
        this.$alert.info("Please wait to complete the uploading");
      }
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (this.isTenantArmgroup()) {
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (origin === "https://trial.ezofis.com") {
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (this.isTenantGOTO()) {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}`;
          }

          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
        } else {
          let domainURL = "";
          let action = "";
          if (
            this.selectedNode === "inbox" ||
            this.selectedNode === "common" ||
            this.selectedNode === "payment"
          ) {
            action = "&action=all";
          }
          if (pdfSupport(ext)) {
            if (this.isTenantArmgroup()) {
              action = "";
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              localStorage.setItem("viewerClose", false);
              this.watchViewerClose = setInterval(
                () => this.checkViewerClose(),
                1000
              );
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (this.isTenantGOTO()) {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            localStorage.setItem("viewerClose", false);
            this.watchViewerClose = setInterval(
              () => this.checkViewerClose(),
              1000
            );
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflowId}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
        }
      }
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action, true);
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            if (this.selectedFile.initiate) {
              let type = 1;
              if (
                this.repositoryDetails.fieldsType === "STATIC" &&
                this.workflow.initiatedBy === "FORM"
              ) {
                type = 2;
              }
              link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/1`;
            } else {
              link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
            }
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.id);
            } else {
              this.$refs.myAttachmentFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";

        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/2`;
        } else {
          link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getFileData(file, data) {
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });
      //this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      if (file.fileData) {
        this.fileData = file.fileData;
      } else {
        this.fileData = payload;
      }
      if (data !== "file") this.fileDetailsSheet = true;
    },

    async attachmentDelete(file) {
      if (!file.fileControl) {
        if (file.initiate) {
          const { error } = await uploadAndIndex.deleteUploadedFiles([file.id]);
          if (error) {
            this.$alert.error(error);
            return;
          }
          this.attachmentList = this.attachmentList.filter(
            (row) => row.id !== file.id
          );
          this.checkList.forEach((row) => {
            if (row.uid === file.uid) {
              row.attach = false;
            }
          });
        } else {
          const { error } = await repository.fileDelete(
            this.workflow.repositoryId,
            file.id
          );
          if (error) {
            this.$alert.error(error);
            return;
          }
          this.getAttachments();
        }
      } else {
        let field = this.workflowModel[file.fieldId];
        if (field.length) {
          let hasField = field.findIndex((item) => {
            return item.fileId === file.id;
          });
          console.log(hasField);
          if (hasField >= 0) {
            this.workflowModel[file.fieldId].splice(hasField, 1);
          }

          let hasValue = this.formUploadFiles.findIndex((item) => {
            return item.fileId === file.id && item.fileId === file.fileId;
          });
          console.log(hasValue);
          if (hasValue >= 0) {
            this.formUploadFiles.splice(hasField, 1);
          }
          if (file.control.length) {
            file.control.forEach((item) => {
              this.workflowModel[item] = "";
            });
          }
        }
        let hasFile = this.attachmentList.findIndex((file) => {
          return file.fileControl;
        });
        console.log(hasFile);
        if (hasFile >= 0) {
          // this.tabs.find((tab) => {
          //   if (tab.value === "ATTACHMENTS") {
          //     tab.count = this.attachmentList.length - 1;
          //   }
          // });
          this.$emit("attachmentCount", this.attachmentList.length - 1);
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    checkViewerClose() {
      if (localStorage.getItem("viewerClose")) {
        if (localStorage.getItem("viewerClose") === "true") {
          this.fileSheet = false;
          localStorage.removeItem("viewerClose");
          clearInterval(this.watchViewerClose);
          if (this.workflow.initiatedBy === "DOCUMENT_SIGN") {
            // this.getSignerSettings();
          }
        }
      }
    },

    // checkTenant() {
    //   if (this.isTenantArmgroup()) {
    //     this.commentAction = 2;
    //     return false;
    //   }
    //   return true;
    // },

    isTenantGOTO() {
      let origin = location.origin;
      if (
        (origin === "https://app.ezofis.com" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        return true;
      }
      return false;
    },

    customCheckUpload() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" &&
        (this.workflowId === 55 || this.workflowId === 2092)
      ) {
        if (this.attachmentList.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },

    async uploadWflowAttachments(e) {
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        if (this.customCheckUpload()) {
          let documentNoField = this.formFields.find(
            (field) => field.label === "Document No"
          );
          if (documentNoField) {
            this.uploadFilename =
              this.workflowModel[documentNoField.value] + ".pdf";
          } else {
            this.uploadFilename = e.target.files[0].name;
          }
        } else {
          this.uploadFilename = e.target.files[0].name;
        }
        const highestLevelObject = this.repositoryField.reduce(
          (prev, curr) => (curr.level > prev.level ? curr : prev),
          this.repositoryField[0]
        );

        const highestLevelId = highestLevelObject.id;

        const fileDataItem = this.fileData.find(
          (item) => highestLevelId == item.id
        );
        if (!this.selectedCheckListName) {
          const newObjectToPush = {
            id: highestLevelObject.id,
            name: highestLevelObject.name,
            value: this.selectedCheckListName,
            type: highestLevelObject.dataType,
          };
          if (!this.fileData.length) {
            this.fileData.push(newObjectToPush);
            console.log(this.fileData, "selectedCheckListName");
          } else if (fileDataItem) {
            fileDataItem.value = "";
          }
          this.showMetaDataEditModal = true;
        }

        if (this.selectedCheckListName) {
          if (fileDataItem) {
            fileDataItem.value = this.selectedCheckListName;
          }

          const newObjectToPush = {
            id: highestLevelObject.id,
            name: highestLevelObject.name,
            value: this.selectedCheckListName,
            type: highestLevelObject.dataType,
          };
          if (!this.fileData.length) {
            this.fileData.push(newObjectToPush);
            console.log(this.fileData, "selectedCheckListName");
          }
          // check all mandatory filled
          const mandatoryItems = this.repositoryField
            .filter((item) => item.isMandatory === true)
            .map((item) => item.id);

          const allMandatoryFilled = mandatoryItems.every((mandatoryId) => {
            const fileItem = this.fileData.find(
              (item) => item.id === mandatoryId
            );
            return fileItem && fileItem.value !== "";
          });

          this.showMetaDataEditModal = !allMandatoryFilled;

          if (allMandatoryFilled) {
            const newObject = {};

            this.fileData.forEach((item) => {
              newObject[item.name] = item.value !== "" ? item.value : "";
            });

            console.log(newObject, "New Object");
            this.saveMetaData(newObject);
          } else {
            this.showMetaDataEditModal = true;
          }
          this.selectedCheckListName = "";
        }
      } else {
        this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e, fields) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.workflowId);
        formData.append("repositoryId", this.workflow.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        if (fields) {
          formData.append("fields", fields);
        } else {
          formData.append("fields", "");
        }
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
          this.documentLoad = setInterval(() => {
            this.getAttachments();
            clearInterval(this.documentLoad);
          }, 15000);
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.checkListRowIndex) {
              row.attach = true;
              return;
            }
          });
        }
        e.target.value = "";
        if (Object.keys(this.reuploadFile).length) {
          await this.attachmentDelete(this.reuploadFile);
          this.reuploadFile = {};
        }
        //this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        if (fields) {
          this.fileData = JSON.parse(fields);
          formData.append("fields", fields);
        }
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        } else {
          formData.append("filename", filename);
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBar");
          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.$alert.success(`Document attached`);
          let fileName = this.repositoryField.reduce((max, curren) =>
            max.level > curren.level ? max : curren
          );
          let name = this.fileData.find((item) => item.name === fileName.name);

          let loading = false;
          if (ext.toLowerCase() !== "pdf") {
            loading = true;
            this.documentLoadList.push(Number(filesList.fileId));
            this.documentLoad = setInterval(() => {
              let file = this.attachmentList.find(
                (row) => this.documentLoadList.indexOf(row.id) > -1
              );
              if (file) {
                this.documentLoadList.splice(
                  this.documentLoadList.indexOf(file.id),
                  1
                );
                file.loading = false;
              }
              if (this.documentLoadList.length === 0) {
                clearInterval(this.documentLoad);
              }
            }, 10000);
          }

          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: fields ? name.value + "." + ext : filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.checkListRowIndex,
            createdBy: this.$store.state.session.id,
            fileData: this.fileData,
            repositoryId: this.workflow.repositoryId,
            loading: loading,
          });
          // this.tabs.find((tab) => {
          //   if (tab.value === "ATTACHMENTS") {
          //     tab.count = this.attachmentList.length;
          //   }
          // });
          this.$emit("attachmentCount", this.attachmentList.length);
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.checkListRowIndex) {
                row.attach = true;
                return;
              }
            });
          }
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    closeMetaDataEditModal() {
      this.showMetaDataEditModal = false;
      if (this.$refs.attachments) {
        this.$refs.attachments.value = null;
      }
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });

      data.forEach((key) => {
        let hasName = this.formFields.find(
          (item) => lowerCase(item.label) === lowerCase(key.name)
        );
        if (hasName && key.value) {
          this.workflowModel[hasName.value] = key.value;
        }
      });

      this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;

      this.$emit("update:attachments", this.attachmentList);
      this.$emit("attachmentCount", this.attachmentList.length);
      this.$emit("update:value", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#sheet .attachmentList,
#sheet .checkList,
#sheet .mailTransactionList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}

#sheet .mailTransactionList {
  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}
</style>
