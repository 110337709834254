<template>
  <div>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      :custom-height="
        selectedNode === 'inbox' ||
        selectedNode === 'common' ||
        selectedNode === 'payment' ||
        initiateProcess ||
        isPortal
          ? '30'
          : '0'
      "
      no-padding
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments(0)"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar :height="commentsSheetHeight">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            >
              <template v-slot:avatar>
                <div
                  v-if="row.embedFileList && row.embedFileList.length"
                  class="file-icons"
                >
                  <FileIcon
                    :key="`Icon-${idx}`"
                    v-tooltip.top="row.embedFileList[0].fileName"
                    :mime-type="fileType(row.embedFileList[0].fileName)"
                    class="file-icon q-mr-sm"
                  />
                </div>
              </template>
            </q-chat-message>
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div
        v-if="
          selectedNode === 'inbox' ||
          selectedNode === 'common' ||
          selectedNode === 'payment' ||
          initiateProcess ||
          isPortal
        "
        class="col borderTop"
      >
        <div class="row">
          <template v-if="checkTenant()">
            <div class="col actions">
              <div
                v-if="attachmentList.length && fileNames.length"
                class="row select-action q-pa-sm action-container"
              >
                <CheckboxField
                  v-model="attachComment"
                  label="Add Comment to PDF"
                  class="q-pr-sm"
                  :class="attachComment ? 'col-auto' : 'col'"
                />

                <CheckboxField
                  v-if="
                    selectedProcess.processId &&
                    selectedProcess.stageType !== 'START' &&
                    !attachComment &&
                    !isPortal
                  "
                  v-model="notifyInitiator"
                  label="Notify Initiator/Requester"
                  class="col-auto notify-cmd"
                />

                <SelectField
                  v-if="attachComment"
                  v-model="selectedFileForAttach"
                  :is-searchable="true"
                  :options="fileNames"
                  class="col field comment-attach attach-cmd"
                />
              </div>

              <CheckboxField
                v-if="
                  selectedProcess.processId &&
                  selectedProcess.stageType !== 'START' &&
                  (attachComment ||
                    !(attachmentList.length && fileNames.length)) &&
                  !isPortal
                "
                v-model="notifyInitiator"
                label="Notify Initiator/Requester"
                :class="notifyInitiatorClass"
                class="col-12 q-pl-sm q-pb-sm q-pt-sm"
              />
              <SingleChoiceField
                v-if="displayInternalPublic"
                v-model="commentAction"
                :options="commentActions"
                :show-options-wrapper="false"
                :options-per-line="2"
                class="action-container"
                :class="{
                  'internal-public': attachmentList.length > 0,
                  'col q-pa-sm': true,
                  'internal-public-margin': attachComment,
                }"
              />
            </div>
          </template>
          <template v-if="isTenantGOTOComments()">
            <SingleChoiceField
              v-model="jiraCommentAction"
              :options="jiraCommentActions"
              :options-per-line="2"
              class="col q-mb-sm actions"
            />
          </template>
        </div>
        <div class="row text-area-action q-ml-sm q-mr-sm">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto; margin-right: 8px">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm comment-actions"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm comment-actions"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Confirm
      v-model="errorModal"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          errorModal = false;
          errorMessage = '';
        }
      "
    >
      <template #title>Error Message</template>

      <template #default>
        <div class="q-py-md">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="red"
          @click="
            {
              errorModal = false;
              errorMessage = '';
            }
          "
        />
      </template>
    </Confirm>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          advancedCommentsSheet = false;
        }
      "
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <template v-if="checkTenant()">
          <div
            v-if="attachmentList.length && fileNames.length"
            class="row actions advance-cmd"
          >
            <CheckboxField
              v-model="attachComment"
              label="Add Comment to PDF"
              class="col-auto q-pr-sm"
              style="margin: auto"
            />

            <SelectField
              v-if="attachComment"
              v-model="selectedFileForAttach"
              :is-searchable="true"
              :options="fileNames"
              class="field comment-attach"
            />
            <CheckboxField
              v-if="
                selectedProcess.processId &&
                selectedProcess.stageType !== 'START' &&
                !isPortal
              "
              v-model="notifyInitiator"
              label="Notify Initiator/Requester"
              class="col-auto q-pl-sm adv-notify-cmd"
            />
          </div>
          <SingleChoiceField
            v-if="checkTenant() && displayInternalPublic"
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="actions q-mr-sm noborder"
          />
        </template>
        <template v-if="isTenantGOTOComments()">
          <SingleChoiceField
            v-model="jiraCommentAction"
            :options="jiraCommentActions"
            :options-per-line="2"
            class="col q-mb-sm actions"
          />
        </template>
        <BaseButton
          label="Post Comments"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import { workflow, integration } from "@/api/factory.js";
import Confirm from "@/components/common/popup/Confirm.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import FileIcon from "@/components/common/FileIcon.vue";

export default {
  name: "Comments",

  components: {
    Sheet,
    Confirm,
    TextAreaField,
    Modal,
    TextBuilderField,
    CheckboxField,
    SelectField,
    SingleChoiceField,
    FileIcon,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    selectedProcess: {
      type: Object,
      default: () => {},
    },

    workflow: {
      type: Object,
      default: () => {},
    },

    selectedNode: {
      type: String,
      required: true,
    },

    initiateProcess: {
      type: Boolean,
      default: false,
    },

    formFields: {
      type: Array,
      default: () => [],
    },

    attachmentList: {
      type: Array,
      default: () => [],
    },

    workflowId: {
      type: Number,
      required: true,
    },

    actionComments: {
      type: Boolean,
      default: false,
    },

    fileNames: {
      type: Array,
      default: () => [],
    },

    comments: {
      type: Array,
      default: () => [],
    },

    displayInternalPublic: {
      type: Boolean,
      default: false,
    },

    isPortal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      commentsSheet: false,
      commentText: "",
      commentsList: [],
      attachComment: false,
      //   fileNames: [],
      selectedFileForAttach: 0,
      advancedCommentsSheet: false,
      advancedCommentText: "",
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 2,
      jiraCommentActions: [
        {
          id: this.$nano.id(),
          label: "This resource",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "All resource",
          value: 2,
        },
      ],
      jiraCommentAction: 1,
      errorMessage: "",
      errorModal: false,
      successModal: false,
      //   displayInternalPublic: false,
      notifyInitiator: false,
      //   actionComments: false,
    };
  },

  computed: {
    commentsSheetHeight() {
      if (
        this.selectedNode === "inbox" ||
        this.selectedNode === "payment" ||
        this.selectedNode === "common" ||
        this.initiateProcess ||
        this.isPortal
      ) {
        if (this.checkCommentsOption() && !this.isCustomizedWorkflow) {
          if (this.attachmentList.length && this.fileNames.length) {
            if (this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 270px)";
            } else if (this.attachComment && !this.displayInternalPublic) {
              return "calc(100vh - 230px)";
            } else if (!this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 225px)";
            } else {
              return "calc(100vh - 200px)";
            }
          } else if (this.displayInternalPublic) {
            return "calc(100vh - 220px)";
          } else if (
            this.selectedProcess.stageType !== "START" &&
            !this.isPortal
          ) {
            return "calc(100vh - 180px)";
          } else {
            return "calc(100vh - 150px)";
          }
        } else {
          return "calc(100vh - 150px)";
        }
      }
      return "";
    },

    notifyInitiatorClass() {
      return !(this.attachmentList.length && this.fileNames.length)
        ? "action-container"
        : "";
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.commentsSheet = true;
        this.commentsList = this.comments;
        if (
          this.commentsSheet &&
          this.selectedProcess.processId &&
          this.selectedProcess.flowStatus === "0"
        ) {
          this.commentsLoad = setInterval(() => {
            this.getComments();
          }, 30000);
        } else {
          clearInterval(this.commentsLoad);
        }
      },
    },
  },

  methods: {
    closeCommentsSheet() {
      this.commentsSheet = false;

      this.$emit("update:comments", this.commentsList);
      this.$emit("commentsCount", this.commentsList.length);
      this.$emit("update:value", false);
    },

    async getComments(workflowId = 0, processId = 0) {
      //   this.tabs.find((tab) => {
      //     if (tab.value === "COMMENTS") {
      //       tab.count = 0;
      //     }
      //   });
      this.commentsList = [];
      if (!workflowId && !processId) {
        workflowId = this.workflowId;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        // this.tabs.find((tab) => {
        //   if (tab.value === "COMMENTS") {
        //     tab.count = this.commentsList.length;
        //   }
        // });

        this.$emit("commentsCount", this.commentsList.length);
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            this.isTenantGOTO() &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.commentText,
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            const { error, payload } = await workflow.insertProcessComment(
              this.workflowId,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.commentText,
                showTo: this.isPortal ? 1 : this.commentAction,
                hasNotifytoInitiated: this.notifyInitiator,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                [payload]
              );
            }
          }
          this.commentText = "";
          this.clearAttach();
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
            hasNotifytoInitiated: this.notifyInitiator,
            embedJson: JSON.stringify({
              repositoryId: this.workflow.repositoryId,
              itemIds: this.attachComment ? [this.selectedFileForAttach] : [],
            }),
          });
          //   this.tabs.find((tab) => {
          //     if (tab.value === "COMMENTS") {
          //       tab.count = this.commentsList.length;
          //     }
          //   });
          this.$emit("commentsCount", this.commentsList.length);
          this.commentText = "";
          this.clearAttach();
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            this.isTenantGOTO() &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.advancedCommentText,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            const { error, payload } = await workflow.insertProcessComment(
              this.workflowId,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.advancedCommentText,
                showTo: this.isPortal ? 1 : this.commentAction,
                hasNotifytoInitiated: this.notifyInitiator,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.clearAttach();
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          //   this.tabs.find((tab) => {
          //     if (tab.value === "COMMENTS") {
          //       tab.count = this.commentsList.length;
          //     }
          //   });
          this.$emit("commentsCount", this.commentsList.length);
        }
        if (this.actionComments) {
          //   this.processRequest(false, false);
          this.$emit("processRequest", false, false);
        }
      }
    },

    clearAttach() {
      this.selectedFileForAttach = 0;
      this.attachComment = false;
      this.notifyInitiator = false;
    },

    async sendCommentsToJira(commentsText, issueId, commentId) {
      this.$store.commit("showLoadingBar");
      if (this.jiraCommentAction === 1) {
        let resourceIDFieldId = this.formFields.find(
          (field) => field.label === "Resource ID"
        )?.value;
        commentsText =
          this.selectedProcess.formData.fields[resourceIDFieldId] +
          "," +
          commentsText;
      }
      commentsText = `${this.$store.state.session.firstName} ${this.$store.state.session.lastName}: ${commentsText}`;
      let input = {
        cloudIntegrationId: this.jiraAccounts[0].id,
        url: this.selectedProcess.jiraIssueInfo.self,
        data_onpremise: {
          body: commentsText,
        },
        issueId: issueId,
        ezCommentId: commentId,
      };

      const { error, payload } = await integration.insertJiraIssueComments(
        input
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error("error posting comments to jira");
        let msg = JSON.parse(error);
        this.errorMessage = msg.errorMessages;
        this.errorModal = true;
        return;
      }
      console.log(payload);
      this.jiraCommentAction = 1;
    },

    isTenantGOTO() {
      let origin = location.origin;
      if (
        (origin === "https://app.ezofis.com" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        return true;
      }
      return false;
    },

    checkCommentsOption() {
      if (this.isTenantArmgroup()) {
        return false;
      } else if (this.isTenantGOTO()) {
        if (Object.keys(this.selectedProcess).length) {
          if (this.selectedProcess.jiraIssueInfo.id) {
            return true;
          }
        }
        return false;
      }
      return true;
    },

    checkTenant() {
      if (this.isTenantArmgroup()) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    isTenantArmgroup() {
      let origin = location.origin;
      if (
        (origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          origin === "http://localhost:80801") &&
        this.$store.state.session.tenantId === 3
      ) {
        return true;
      }
      //   else if (origin === "http://localhost:8080") {
      //     return true;
      //   }
      return false;
    },

    isTenantGOTOComments() {
      if (Object.keys(this.selectedProcess).length) {
        if (this.isTenantGOTO() && this.selectedProcess.jiraIssueInfo.id) {
          return true;
        }
      }

      return false;
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },
  },
};
</script>

<style lang="scss">
.select-action {
  padding-top: 10px;

  align-items: center;
}

.action-container {
  border-top: 1px solid #cfd5dc;
}

.internal-public {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #cfd5dc;
  padding-top: 10px;
}

.internal-public-margin {
  margin-top: 5px;
}
</style>
