import { render, staticRenderFns } from "./CustomForm.vue?vue&type=template&id=1b34e972&scoped=true&"
import script from "./CustomForm.vue?vue&type=script&lang=js&"
export * from "./CustomForm.vue?vue&type=script&lang=js&"
import style0 from "./CustomForm.vue?vue&type=style&index=0&id=1b34e972&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b34e972",
  null
  
)

export default component.exports
import QSpace from 'quasar/src/components/space/QSpace.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpace,QChip,QAvatar,QChatMessage});
