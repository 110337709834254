<template>
  <div id="addTask">
    <Sheet :value="value" :width="taskWidth" no-padding @input="closeSheet">
      <template #title>
        <slot name="title">{{ title }}</slot>
      </template>

      <template #actions>
        <BaseActionButton
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          class="q-mr-sm"
          @click="toggleWidth"
        />
      </template>

      <!-- form -->
      <template #default>
        <div id="content-wrapper" class="row">
          <div id="task-form-view" class="col">
            <BaseScrollbar height="calc(100vh - 60px)" class="q-pa-md">
              <RenderForm
                no-padding
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
                :form-model="taskModel"
                :is-readonly="mode === 'VIEW'"
                @save="saveTask"
              />
            </BaseScrollbar>
          </div>
          <div class="col-auto q-mr-sm leftBorder">
            <BaseActionButton
              v-for="tab in tabs"
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>

            <BaseActionButton
              v-tooltip.left="'workflows'"
              is-flat
              no-border
              icon="o_account_tree"
              color="primary"
              class="tabButtons"
            >
              <q-menu
                transition-show="scale"
                transition-hide="scale"
                anchor="bottom end"
                self="top right"
              >
                <BaseScrollbar width="240px" class="q-pb-sm">
                  <!-- columns -->

                  <ListItem
                    v-for="workflow in taskWorkflowList"
                    :key="workflow.id"
                    icon="mdi-sitemap"
                    :label="workflow.label"
                    @click="getForm(workflow)"
                  >
                  </ListItem>
                </BaseScrollbar>

                <!-- ... -->
              </q-menu>
            </BaseActionButton>
          </div>
        </div>
      </template>

      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="addTaskSheet"
      :width="taskWidth"
      has-footer
      @input="closeAddTaskSheet"
    >
      <template #title>
        <slot name="title">{{ headerTitle }}</slot>
      </template>

      <template #actions>
        <BaseActionButton
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          class="q-mr-sm"
          @click="toggleWidth"
        />
      </template>

      <!-- form -->
      <template #default>
        <div id="content-wrapper" class="row">
          <div id="form-view" class="col">
            <BaseScrollbar height="calc(100vh - 145px)" class="q-pa-md">
              <RenderForm
                no-padding
                :workflow-id="workflowFormId"
                :panels="addWorkflowForm.panels"
                :secondary-panels="addWorkflowForm.secondaryPanels"
                :form-settings="addWorkflowForm.settings"
                :workflow-form-model.sync="workflowFormModel"
                :save-action.sync="saveAction"
              />
            </BaseScrollbar>
          </div>

          <div class="col-auto q-mr-sm leftBorder">
            <BaseActionButton
              v-for="tab in tabs"
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </div>
        </div>
      </template>

      <!-- footer -->
      <template #footer>
        <div v-if="actions" class="footer row">
          <template v-for="action in actions">
            <BaseButton
              v-if="
                action === 'Approve' ||
                action === 'Complete' ||
                action === 'Rightsize'
              "
              :key="action"
              :label="action"
              color="positive"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="
                action === 'Reject' ||
                action === 'Terminate' ||
                action === 'Force Close'
              "
              :key="action"
              :label="action"
              color="red"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="action === 'Forward' || action === 'Reply'"
              :key="action"
              :label="action"
              color="orange"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else
              :key="action"
              :label="action"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
          </template>
        </div>
      </template>

      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="attachmentList.length ? true : false"
      :upload="true"
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments"
        />
      </template>

      <template #upload>
        <div class="hint" @click="$refs.attachments.click()">
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template #subActions>
        <template>
          <CheckboxField
            v-model="selectAll"
            class="col-auto q-pt-sm q-pb-sm"
            @input="fileSelectAll"
          />
          Select All
        </template>

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-if="selectedFileCount > 1 && false"
          v-tooltip.top="'merge documents'"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <input
          ref="attachments"
          type="file"
          class="hidden"
          @change="uploadAttachments"
        />
        <template v-for="file in attachmentList">
          <div :key="file.id" class="attachmentList">
            <div class="title row">
              <div class="col-auto">
                <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                <BaseIcon
                  :name="
                    file.checked
                      ? 'eva-checkmark-circle-2'
                      : 'eva-radio-button-off-outline'
                  "
                  color="secondary"
                  class="q-mr-sm"
                  @click="file.checked = !file.checked"
                />
              </div>
              <div class="col-auto">
                <FileIcon
                  :mime-type="fileType(file.name)"
                  class="mini-avatar q-mr-sm"
                />
              </div>
              <div class="col ellipsis" @click="openFile(file)">
                {{ file.name }}
              </div>
              <div class="col-auto">
                <BaseActionButton
                  v-tooltip.top="'delete'"
                  icon="eva-trash-2-outline"
                  color="red"
                  no-border
                  is-flat
                  size="20px"
                  @click.stop="attachmentDelete(file)"
                />
              </div>
            </div>

            <div class="meta">
              <!-- created at -->

              <div>{{ $day.format(file.createdAt) }}</div>

              <!-- ... -->

              <!-- created by -->

              <template v-if="file.createdBy">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ file.createdByEmail }}</div>
              </template>

              <!-- ... -->

              <!-- size -->

              <template v-if="file.size">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ fileSize(file.size) }}</div>
              </template>

              <!-- ... -->
            </div>
          </div>
        </template>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 155px)">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div class="col q-pa-sm borderTop">
        <div class="row">
          <!-- <div class="col-auto" style="margin: auto">
            <q-avatar
              color="primary-11 q-mr-sm"
              text-color="white"
              icon="mdi-comment-account-outline"
              size="32px"
            />
          </div> -->
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>
    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Workflows </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        />

        <BaseActionButton
          v-tooltip.top="'Add Workflows'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getworkflowForm(workflowFormId)"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="task in processInfo">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="getWorkflowAudit(task.requestNo)"
                >
                  Request No: {{ task.requestNo }}
                </div>

                <template v-if="task.actionUser">
                  <q-chip size="10px" color="yellow" text-color="white">
                    {{ task.actionUser }}
                  </q-chip>
                </template>
                <q-chip
                  size="10px"
                  :color="getColor(task.flowStatus)"
                  text-color="white"
                >
                  {{ task.flowStatus === "0" ? "Open" : "Completed" }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">
                Raised By: {{ task.raisedBy }}
                <BaseIcon name="mdi-circle-small" />
                Raised At: {{ $day.format(task.raisedAt) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>
    <Sheet
      v-model="processSheet"
      width="100vw"
      has-footer
      no-padding
      @input="processSheet = false"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <!-- content -->

      <div id="content-wrapper" class="row">
        <div id="form-view" class="col">
          <BaseScrollbar height="calc(100vh - 145px)">
            <RenderForm
              :form-id="formIdName"
              :panels="addWorkflowForm.panels"
              :secondary-panels="addWorkflowForm.secondaryPanels"
              :form-settings="addWorkflowForm.settings"
              :form-model="formModel"
              :save-action.sync="saveAction"
              :workflow-form-model.sync="workflowFormModel"
            />
          </BaseScrollbar>
        </div>
        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
      </div>

      <!-- ... -->
    </Sheet>
    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>
  </div>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import {
  form,
  uploadAndIndex,
  repository,
  user,
  common,
  workflow,
  report,
} from "@/api/factory.js";
import { omit } from "lodash-es";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";
import { pdfSupport, htmlSupport, fileSupport } from "@/helpers/file-format.js";
import axios from "axios";
import ListItem from "@/components/common/ListItem.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import { mapState } from "vuex";
import EmailSharing from "@/components/common/EmailSharing.vue";

export default {
  name: "AddTask",

  components: {
    Sheet,
    RenderForm,
    FileIcon,
    CheckboxField,
    TextAreaField,
    ListItem,
    TextBuilderField,
    Modal,
    EmailSharing,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    formSettings: {
      type: Object,
      required: true,
    },

    panels: {
      type: Array,
      required: true,
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    formId: {
      type: Number,
      default: 0,
    },

    taskModel: {
      type: Object,
      default: () => {},
    },

    mode: {
      type: String,
      default: "",
    },

    taskName: {
      type: String,
      default: "",
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    workflowTask: {
      type: Boolean,
      default: false,
    },

    taskWorkflowList: {
      type: Array,
      default: () => [],
    },

    selectedTask: {
      type: Object,
      default: () => {},
    },

    documentTask: {
      type: Boolean,
      default: false,
    },

    repositoryFile: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      taskWidth: "720px",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
      ],
      attachmentSheet: false,
      addTaskSheet: false,
      taskSheet: false,
      commentsSheet: false,
      commentText: "",
      commentsList: [],
      selectAll: false,
      attachmentLoad: null,
      attachmentList: [],
      fileSheet: false,
      selectedFile: {},
      actions: [],
      workflowsSheet: true,
      workflowList: [],
      saveAction: "",
      workflowId: 0,
      addWorkflowForm: [],
      workflowPanels: [],
      form: [],
      workflowName: "",
      workflowDescription: "",
      workflowFormId: 0,
      workflowFormModel: {},
      processId: 0,
      transactionId: 0,
      workflowDetails: [],
      countWorkflow: 0,
      processInfo: [],
      workflowFormTaskList: {},
      formList: {},
      formStage: {},
      processSheet: false,
      formDetails: {},
      formIdName: "",
      formModel: {},
      headerTitle: "",
      advancedCommentsSheet: false,
      advancedCommentText: "",
      emailSharingSheet: false,
      selectedFileList: [],
    };
  },

  computed: {
    ...mapState(["LoadingBarPage"]),

    isMaximized() {
      return this.taskWidth === "100vw";
    },

    title() {
      if (this.mode === "VIEW") return "View Task";
      if (this.mode === "EDIT") return "Edit Task";
      if (this.mode === "FORWARD") return "Forward Task";
      if (this.mode === "COPY") return "Copy Task";

      return "Add Task";
    },

    formName() {
      return this.formSettings.general.name;
    },

    formDescription() {
      return this.formSettings.general.description;
    },

    selectedFileCount() {
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    scrollbarHeight() {
      const height = this.columns.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },
  },

  watch: {
    taskModel: {
      immediate: true,
      handler() {
        if (this.taskModel.id) {
          this.attachmentList = [];
          this.getComments();
          this.getAttachments();
        }
      },
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          this.initiateRequest();
        }
      },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.taskModel.id) {
          //this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },
  },

  methods: {
    toggleWidth() {
      this.taskWidth = this.taskWidth === "100vw" ? "720px" : "100vw";
    },

    closeSheet() {
      this.$emit("close", false);
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeAddTaskSheet() {
      this.addTaskSheet = false;
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    getColor(label) {
      if (label === "0") {
        return "orange";
      } else if (label === "1" || label === "Medium") {
        return "green";
      } else {
        return "primary";
      }
    },

    showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        this.attachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.commentsSheet = true;
      } else if (tab === "HISTORY") {
        //this.$emit("history", taskId);
        this.$emit(
          "history",
          this.formList[0].value[0].processId,
          this.formList[0].workflowId,
          this.formList[0].value[0].requestNo
        );
      } else if (tab === "PRINT") {
        window.print();
      }
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    showHistory() {
      //this.$emit("history", processId);
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (origin === "https://trial.ezofis.com") {
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (origin === "https://app.ezofis.com") {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
        } else {
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (origin === "https://trial.ezofis.com") {
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (origin === "https://app.ezofis.com") {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
        }
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      this.fileSheet = true;
    },

    async documentShare() {
      this.selectedFileList = [];
      this.attachmentList.forEach((item) => {
        if (item.checked) {
          this.selectedFileList.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.emailSharingSheet = true;
    },

    async saveTask(fieldsModel) {
      const unNecessaryKeys = [
        "itemid",
        "createdBy",
        "createdAt",
        "modifiedBy",
        "modifiedAt",
        "icon",
        "buttonAction",
        "formId",
        "status",
        "color",
        "link",
        "selected",
        "isDeleted",
        "createdByEmail",
        "modifiedByEmail",
        "emailStatus",
        "expandSubworkflow",
      ];

      const _fieldsModel = omit(fieldsModel, unNecessaryKeys);
      // console.log(_fieldsModel);

      try {
        this.$store.commit("showLoadingBarPage");

        const { id, ...payload } = _fieldsModel;
        // console.log(id, payload);
        let entryId = 0;

        if (this.mode === "COPY") {
          entryId = 0;
        } else {
          entryId = id || 0;
        }
        let input = {};
        if (entryId) {
          input = {
            fields: payload,
          };
        } else {
          let uploadedIdList = [];
          if (this.attachmentList.length) {
            this.attachmentList.forEach((file) => {
              uploadedIdList.push(file.id);
            });
          }
          input = {
            fields: payload,
            task: {
              comments: this.commentsList,
              fileIds: uploadedIdList,
            },
          };
        }

        const { data, error } = await form.saveFormEntry(
          this.formId,
          entryId,
          input
        );

        if (this.workflowDetails.length) {
          this.linkTaskWorkflowProcess(data.output);
        }

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (id) {
          this.$alert.success(`${this.taskName} edited successfully`);
        } else {
          if (this.documentTask) {
            this.linkTaskDocument(data.output);
          }
          this.$alert.success(`New task added successfully`);
        }
        this.getUsers(input.fields);
        this.closeSheet();
        if (this.workflowTask || this.documentTask) {
          this.$emit("addEntry", this.formId, data.output);
        } else {
          this.$emit("refresh", this.workflowId);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("hideLoadingBarPage");
      }
    },

    async linkTaskDocument(entryId) {
      let input = {
        wFormId: this.formId,
        formEntryId: entryId,
        fileInfo: {
          repositoryId: this.repositoryFile.repositoryId,
          itemId: this.repositoryFile.id,
        },
      };
      const { error } = await form.linkTaskDocument(input);
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await form.getFormComments(
        this.formId,
        this.taskModel.id
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.taskModel.id) {
          this.$store.commit("showLoadingBar");
          const { error } = await form.insertFormComment(
            this.formId,
            this.taskModel.id,
            {
              comments: this.commentText,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.commentText = "";
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.commentText = "";
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.taskModel.id) {
          this.$store.commit("showLoadingBar");
          const { error } = await form.insertFormComment(
            this.formId,
            this.taskModel.id,
            {
              comments: this.advancedCommentText,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
      }
    },

    async uploadAttachments(e) {
      if (this.taskModel.id) {
        const file = e.target.files[0];
        this.$store.commit("showLoadingBar");
        const formData = new FormData();
        formData.append("formId", this.formId);
        formData.append("repositoryId", this.repositoryId);
        formData.append("entryId", this.taskModel.id);
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/form/taskAttachmentWithEntryId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          this.$store.commit("hideLoadingBar");
          e.target.value = "";
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
        } catch (error) {
          this.$store.commit("hideLoadingBar");
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
      } else {
        this.$store.commit("showLoadingBar");
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("formId", this.formId);
        formData.append("repositoryId", this.repositoryId);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });

          const { status, data } = response;
          this.$store.commit("hideLoadingBar");
          e.target.value = "";
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: file.name,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            checked: false,
          });
        } catch (error) {
          this.$store.commit("hideLoadingBar");
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments() {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      let count = this.attachmentList.length;
      this.$store.commit("showLoadingBar");
      const { error, payload } = await form.getFormAttachments(
        this.formId,
        this.taskModel.id
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          clearInterval(this.attachmentLoad);
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });
      } else {
        this.attachmentList = [];
      }
    },

    async attachmentDelete(file) {
      this.$store.commit("showLoadingBar");
      if (file.initiate) {
        const { error } = await uploadAndIndex.deleteUploadedFiles([file.id]);
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$store.commit("hideLoadingBar");
        this.attachmentList = this.attachmentList.filter(
          (row) => row.id !== file.id
        );
      } else {
        const { error } = await repository.fileDelete(
          file.repositoryId,
          file.id
        );
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$store.commit("hideLoadingBar");
        this.getAttachments();
      }
    },

    validateRequest(action) {
      this.saveAction = action;
    },
    async initiateRequest() {
      let inputData = {};
      inputData = {
        workflowId: this.workflowId,
        transactionId: 0,
        review: this.saveAction,
        formData: {
          formId: this.workflowFormId,
          formEntryId: this.form.entryCount,
          fields: this.workflowFormModel,
        },
      };

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");
      this.saveAction = "";

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success(`${payload.requestNo} Request Initiated`);
      this.workflowDetails.push({
        workflowId: this.workflowId,
        processId: payload.processId,
        transactionId: payload.transactionId,
      });

      if (this.selectedTask.id) {
        this.linkTaskWorkflowProcess(this.selectedTask.id);
      }
      this.closeAddTaskSheet();
      this.$emit("refresh", this.workflowId);
    },

    async getForm(workflow) {
      this.workflowId = workflow.id;
      this.headerTitle = workflow.stage;
      this.actions = workflow.actions;
      if (this.selectedTask.formId && this.selectedTask.id) {
        // await this.$emit("WorkflowList", this.workflowId);
        await this.getWorkflowList(this.workflowId);
      }
      if (workflow.formId) {
        this.workflowFormId = workflow.formId;
        if (this.processInfo.length) {
          this.countWorkflow = this.processInfo.length;
          this.taskSheet = true;
        } else {
          const { error, payload } = await form.getForm(this.workflowFormId);
          if (error) {
            this.$alert.error(error);
            return;
          }
          if (payload) {
            this.form = payload;
            this.workflowName = this.form.name;
            this.workflowDescription = this.form.description;
            this.addWorkflowForm = JSON.parse(payload.formJson);
            this.workflowPanels = this.addWorkflowForm.panels;
            this.addTaskSheet = true;
          }
        }
      }
    },

    async getworkflowForm(action) {
      this.closeTaskSheet();
      const { error, payload } = await form.getForm(action);
      this.workflowFormId = action;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.form = payload;
        this.workflowName = this.form.name;
        this.workflowDescription = this.form.description;
        this.addWorkflowForm = JSON.parse(payload.formJson);
        this.workflowPanels = this.addWorkflowForm.panels;
        this.addTaskSheet = true;
      }
    },

    async getworkflowDetailsForm(action) {
      const { error, payload } = await form.getForm(action);
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.form = payload;
        this.workflowName = this.form.name;
        this.workflowDescription = this.form.description;
        this.addWorkflowForm = JSON.parse(payload.formJson);
        this.workflowPanels = this.addWorkflowForm.panels;
        this.processSheet = true;
        this.$store.commit("hideLoadingBarPage");
      }
    },

    async linkTaskWorkflowProcess(taskFormEntryId) {
      let inputData = {};
      inputData = {
        wFormId: this.formId,
        formEntryId: taskFormEntryId,
        workflow: this.workflowDetails,
      };
      const { error } = await form.linkTaskWorkflowProcess(inputData);

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getWorkflowList(workflowId) {
      let inputData = {};
      inputData = {
        wId: workflowId,
        fId: this.selectedTask.formId,
        entryId: this.selectedTask.id,
      };
      const { error, payload } = await form.getWorkflowTaskList(
        workflowId,
        this.selectedTask.formId,
        this.selectedTask.id,
        inputData
      );
      this.workflowFormTaskList = payload;
      if (payload.processInfo) {
        this.processInfo = payload.processInfo;
      }
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getWorkflowAudit(id) {
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "requestNo",
                condition: "IS_EQUALS_TO",
                value: id,
              },
            ],
          },
        ],
        currentPage: 1,
        itemsPerPage: 10,
        mode: "",
      });
      const { data, meta } = payload;
      this.formList = data || {};
      this.formStage = meta;
      if (this.formList[0].value[0].formData) {
        let keys = Object.keys(this.formList[0].value[0].formData.fields);
        let newValues = {};
        keys.forEach((key) => {
          newValues[key] = this.validateTypeValue(
            this.formList[0].value[0].formData.fields[key]
          );
        });
        this.formModel = newValues;
      }
      this.headerTitle = `${this.formList[0].value[0].name}`;
      this.formIdName = String(this.formList[0].value[0].formData.formId);
      this.getworkflowDetailsForm(this.formIdName);

      if (error) {
        this.$alert.error(error);
        return;
      }
    },
    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    async getUsers(input) {
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }
      let data = {
        id: 0,
        title: input["e_NFnW0JxrLpZxqPeJS7K"],
        status: input["11m7YRT-7kdLx-g6iGojc"],
        remarks: input["2XOJMCR_DVb-5TwVPVdEX"],
        category: "TASK",
        createdBy: input["pM6Vly-JRlfroRrziE1jr"],
        lastActionBy: this.$store.state.session.email,
      };
      const userEmail = data.createdBy;
      const matchedUser = payload.filter((user) =>
        userEmail.includes(user.value)
      );
      const userIds = matchedUser.map((user) => user.id);
      userIds.forEach((userId) => {
        const newData = { ...data, createdBy: userId }; // Create a new object with a different ID
        this.insertNotification(newData);
      });
      console.log("email", matchedUser, userIds);
    },

    async insertNotification(input) {
      const { error } = await common.insertNotification(input);
      if (error) {
        this.$alert.error(error);
        return;
      }
      //this.$alert.success("Notification inserted succesfully");
    },
  },
};
</script>

<style lang="scss" scoped>
#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}

#sheet .attachmentList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }
}

#sheet {
  #task-form-view {
    .footer {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 0;
      bottom: 16px;
      width: 100%;

      .label {
        @extend .text-sm;
        color: var(--icon-color-inverted);
        margin-right: 4px;
      }

      img {
        height: 16px;
        width: auto;
      }
    }
  }
}

#sheet .attachmentList,
#sheet .taskEntries,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}
</style>
